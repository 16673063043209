import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import BaseImage from '@pretto/bricks/website/shared/components/Image'
import Link from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export const Thumbnail = styled(Link)`
  display: flex;
  cursor: pointer;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: column;
    max-width: ${g(36)};
  }
`

export const Image = styled(BaseImage).attrs(({ $isMobile }) =>
  $isMobile
    ? {
        options: {
          aspectRatio: '16:9',
          crop: 'fill',
          quality: 85,
          width: 122,
        },
      }
    : {
        options: {
          aspectRatio: '16:9',
          crop: 'fill',
          quality: 85,
          width: 284,
        },
      }
)`
  display: block;
  padding-right: ${g(3)};
  width: 122px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
    padding-right: 0px;
    margin-bottom: ${g(3)};
    border-radius: ${g(1)};
  }
`

export const Date = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    ${typo.caption12};
    margin-bottom: ${g(1)};
  }
`

export const Title = styled.div`
  ${typo.heading16};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(1)};
  }
`

export const Text = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    ${typo.caption12};
  }
`
