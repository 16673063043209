import { Thumbnail, ThumbnailProps } from '@pretto/zen/seo/thumbnails/Thumbnail/Thumbnail'

import * as S from './SectionMoreSimple.styles'

export interface SectionMoreSimpleProps {
  articles: ThumbnailProps[]
  title: string
}

export const SectionMoreSimple = ({ articles, title, ...props }: SectionMoreSimpleProps) => (
  <S.SectionMoreSimple {...props}>
    <S.Title>{title}</S.Title>
    <S.ArticlesSection>
      {articles.map(article => (
        <S.ThumbnailsContainer key={article.title}>
          <Thumbnail {...article} />
        </S.ThumbnailsContainer>
      ))}
    </S.ArticlesSection>
  </S.SectionMoreSimple>
)
