import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const SectionMoreSimple = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};
  display: flex;
  flex-direction: column;
  padding: ${g(4)} ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${grid()};
    padding: ${g(7)} 0px ${g(5)} 0px;
  }
`

export const Title = styled.div`
  ${typo.heading32};
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, -2])};
  }
`

export const ArticlesSection = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, -2])};
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${g(3)};
  }
`

export const ThumbnailsContainer = styled.div`
  & + & {
    margin-top: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    & + & {
      margin-top: 0px;
    }
  }
`
