import { useBreakpoint } from '@pretto/bricks/assets/utility'

import * as S from './Thumbnail.styles'

export interface ThumbnailProps {
  date: string
  href: string
  src: string
  text: string
  title: string
}

export const Thumbnail = ({ date, src, text, title, ...props }: ThumbnailProps) => {
  const { isMobile } = useBreakpoint()

  return (
    <S.Thumbnail {...props}>
      <S.Image alt={title} $isMobile={isMobile} path={src} />
      <S.Date>{date}</S.Date>
      <S.Title>{title}</S.Title>
      <S.Text>{text}</S.Text>
    </S.Thumbnail>
  )
}
